import React, { useState, useEffect } from 'react';
import { Row, Col, Form, FormControl } from 'react-bootstrap';
import { format, formatInTimeZone } from 'date-fns-tz';

import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import FloatingLabelInput from '../../../common/components/FloatingLabelInput';
import FloatingLabelSelect from '../../../common/components/FloatingLabelSelect';
import TestSurveyFormV2 from '../forms/TestSurveyFormV2';
import { VaccinePdfLabelPrinter } from '../PdfLabelPrinter';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare, faExclamationCircle } from '@fortawesome/pro-regular-svg-icons';


export const ScreeningStep = ({
  completeReasons=[],
  test,
  showError=false,
  updateTest,
}) => {
  return (
    <div className="service-card-section">
      <h4 className="service-card-section-title">Participant screening response</h4>
      <TestSurveyFormV2
        disabled={test.administered}
        isInvalid={showError && completeReasons.includes("admin_approved")}
        test={test}
        updateTest={updateTest}
      />
    </div>
  );
};

export const VaccineProgramStep = ({
  fundingSourceOptions,
  options,
  requestEligibility,
  requestFundingSource,
  subTitle,
  test,
  updateTest,
  vaccineProgramField,
}) => {
  const extractKeys = (options) => {
    const keys = Object.keys(options);
    // make "unknown" the last option if present and does not have a code so "None of the above" makes sense
    if (keys.includes("unknown") && !options["unknown"]["code"]) keys.push(keys.splice(keys.indexOf("unknown"), 1)[0]);
    return keys;
  };
  const keys = extractKeys(options);
  return (
    <div className="service-card-section">
      <h4 className="service-card-section-title">Vaccine programs</h4>
      <div className="mx-3">
        {requestEligibility && (
          <>
            <div className="mt-2 mb-3">
              {subTitle}
            </div>
            <div className="mx-2">
              {keys
                .map((option) => {
                  const code = options[option]["code"] ? `(${options[option]["code"]})` : "";
                  const label = option === 'unknown' && !options[option]["code"]
                    ? 'None of the above'
                    : `${options[option]["label"]} ${code}`;
                  return (
                    <FabrxCheckbox
                      name={option}
                      checked={test.vaccine_result[vaccineProgramField] === option}
                      radio
                      onChange={() => {
                        const updatedTest = {...test, saved: false};
                        updatedTest["vaccine_result"][vaccineProgramField] = option;
                        updateTest(updatedTest);
                      }}
                      label={label}
                    />
                  )
                })
              }
            </div>
          </>
        )}
        {requestFundingSource && (
          <div className="my-3">
            <h6 className="my-2">Funding source</h6>
            <div className="mx-2">
              {Object.keys(fundingSourceOptions)
                .map((option) => {
                  return (
                    <FabrxCheckbox
                      name={option}
                      checked={test.vaccine_result?.funding_source === option}
                      radio
                      onChange={() => {
                        updateTest({
                          ...test,
                          saved: false,
                          vaccine_result: {
                            ...test.vaccine_result,
                            funding_source: option,
                          },
                        });
                      }}
                      label={`${fundingSourceOptions[option]["label"]} (${fundingSourceOptions[option]["code"]})`}
                    />
                  )
                })
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const VaccineLotStep = ({
  completeReasons,
  test,
  updateTest,
  vaccineRoute,
  setVaccineRoute,
  showError,
}) => {
  const [vaccineResult, testConfiguration] = [
    test.vaccine_result,
    test.test_configuration,
  ];

  const vaccineLotOptions =
    testConfiguration.vaccine_lots &&
    testConfiguration.vaccine_lots.map((vaccineLot) => {
      return { label: vaccineLot.lot_number, value: vaccineLot.lot_number };
    });

  return (
    <div className="service-card-section">
      <div className="d-flex justify-content-between">
        <h4 className="service-card-section-title">Vaccine lot</h4>
        <a
          target="_blank"
          href={`/test_groups/${test.test_group.id}/appointment_slot_groups/${test.appointment_slot_group.id}/vaccine_lots?active=true`}
          className="font-weight-bold"
          variant="primary"
        >
          Manage lots
        </a>
      </div>
      <Row>
        <Col sm={6} xs={12} className="my-2">
          <h6>Lot number</h6>
          <FloatingLabelSelect
            className={showError && completeReasons.includes("vaccine_lot_id") ? "is-invalid" : ""}
            value={vaccineLotOptions.filter(o => o.value === vaccineResult.lot_number)}
            onChange={(e) => {
              const vaccineLot = testConfiguration.vaccine_lots.find(
                (vl) => vl.lot_number === e.value,
              );
              updateTest({
                ...test,
                saved: false,
                vaccine_result: {
                  ...vaccineResult,
                  lot_number: vaccineLot.lot_number,
                  dose: vaccineLot.vaccine_route.default_dose.toString(),
                  expiration_date: vaccineLot.expiration_date,
                  vaccine_lot_id: vaccineLot.id,
                  administration_type:
                    vaccineLot.vaccine_route.default_administration_type,
                },
              });
              setVaccineRoute(vaccineLot.vaccine_route);
            }}
            options={vaccineLotOptions}
            filledValue={vaccineResult.lot_number}
          />
        </Col>
        {vaccineRoute && vaccineResult.lot_number && (
          <div className="mt-3">
            <Row>
              <Col xl={2} lg={3} md={4} sm={5} xs={6}><b>Vaccine:</b></Col>
              <Col>{vaccineRoute.trade_name_with_manufacturer}</Col>
            </Row>
            <Row>
              <Col xl={2} lg={3} md={4} sm={5} xs={6}><b>Formulation:</b></Col>
              <Col>{vaccineRoute.formulation}</Col>
            </Row>
            <Row>
              <Col xl={2} lg={3} md={4} sm={5} xs={6}><b>Expiration:</b></Col>
              <Col>{vaccineResult.expiration_date && format(new Date(vaccineResult.expiration_date), 'yyyy-MM-dd')}</Col>
            </Row>
          </div>
        )}
      </Row>
    </div>
  );
};

export const AdministrationStep = ({ test, updateTest, showError=false, completeReasons=[] }) => {
  const disabledVaccineDoseIds = test.test_configuration["disabled_vaccine_route_and_dose_ids"]["vaccine_dose_ids"].map(v => parseInt(v));
  const disabledVaccineRouteIds = test.test_configuration["disabled_vaccine_route_and_dose_ids"]["vaccine_route_ids"].map(v => parseInt(v));
  const [vaccineResult, vaccineRoute, testConfiguration, vaccineDoses, vaccineDoseId] = [
    test.vaccine_result,
    test.vaccine_result.vaccine_route,
    test.test_configuration,
    test.vaccine_result.vaccine_route.vaccine_doses ? test.vaccine_result.vaccine_route.vaccine_doses.filter(vd => vd.active === true && !disabledVaccineDoseIds.includes(vd.id)) : [],
    test.vaccine_result.vaccine_dose_id
  ];
  const isDefaultDoseDisabled = disabledVaccineRouteIds.includes(vaccineRoute.id);
  const patientRefusedService = test.administered === false;
  const allVaccineDoses = vaccineDoses;
  if (!isDefaultDoseDisabled) allVaccineDoses.unshift({ id: null, dose_name: vaccineRoute.default_vaccine_dose_name, size: vaccineRoute.default_dose, dose_type: "default" });
  const onlyOneDoseAvailable = allVaccineDoses.length === 1;
  const defaultDose = allVaccineDoses.find(vd => vd.id == null);
  const initialDose = onlyOneDoseAvailable
        ? allVaccineDoses[0]
        : vaccineDoseId
          ? vaccineDoses.find(vd => vd.id === vaccineDoseId)
          : test.administered
            ? {dose_name: defaultDose?.dose_name, size: vaccineResult.dose}
            : defaultDose

  const [selectedDose, setSelectedDose] = useState((!!vaccineResult.id || onlyOneDoseAvailable) ? {
    dose_name: initialDose?.dose_name,
    size: initialDose?.size,
  } : {
    dose_name: vaccineResult.dose_name,
    size: vaccineResult.dose,
  });

  if (initialDose && !vaccineResult.dose_name && onlyOneDoseAvailable) {
    updateTest({
      ...test,
      vaccine_result: {
        ...vaccineResult,
        dose_name: initialDose.dose_name || "N/A",
        vaccine_dose_id: initialDose.id,
        dose: initialDose.size || 0.0
      },
    });
  }

  const dosesInOrder = ["default", "dose_1", "dose_2", "dose_3", "dose_4", "dose_5", "booster_dose", "second_booster_dose", "additional_dose"];
  const administeredDoseOptions = allVaccineDoses.sort((a, b) => {
      return dosesInOrder.indexOf(a.dose_type) - dosesInOrder.indexOf(b.dose_type)
    }).map((vd) => {
      return { value: vd, label: vd.dose_name }
    });

  return (
    <div className="service-card-section">
      <h4 className="service-card-section-title">Service administration</h4>
      <Row>
        <Col lg={4} sm={6} xs={12}>
          {allVaccineDoses?.length > 0 && (
            <Form.Group>
              <Form.Label>Dose schedule</Form.Label>
              <FloatingLabelSelect
                className={showError && completeReasons.includes("dose_name") ? "is-invalid" : ""}
                name="administered_dose"
                value={administeredDoseOptions.filter(o => o.label === selectedDose.dose_name)}
                onChange={(vd) => {
                  setSelectedDose({dose_name: vd.value?.dose_name, size: vd.value?.size});
                  updateTest({
                    ...test,
                    saved: false,
                    vaccine_result: { ...vaccineResult, dose: vd.value?.size, vaccine_dose_id: vd.value?.id, dose_name: vd.value?.dose_name },
                  })
                }}
                isDisabled={patientRefusedService}
                options={administeredDoseOptions}
                filledValue={selectedDose.dose_name}
              />
            </Form.Group>
          )}
        </Col>
        <Col lg={4} sm={6} xs={12}>
          <Form.Group>
            <Form.Label>Dosage</Form.Label>
            <FloatingLabelInput
              validation={showError && completeReasons.includes("dose") ? "is-invalid" : ""}
              name="dose"
              value={selectedDose.size}
              onChange={(e) => {
                setSelectedDose({dose_name: selectedDose.dose_name, size: e.target.value})
                updateTest({
                  ...test,
                  saved: false,
                  vaccine_result: { ...vaccineResult, dose: e.target.value },
                });
              }}
              isDisabled={patientRefusedService}
            />
          </Form.Group>
        </Col>
        <Col lg={4} sm={6} xs={12}>
          <Form.Group>
            <Form.Label>Administered type</Form.Label>
            <FloatingLabelSelect
              className={showError && completeReasons.includes("administration_type") ? "is-invalid" : ""}
              name="administration_type"
              value={testConfiguration.administration_type_options.filter(o => o.value === vaccineResult.administration_type)}
              onChange={(at) => {
                updateTest({
                  ...test,
                  saved: false,
                  vaccine_result: {
                    ...vaccineResult,
                    administration_type: at.value,
                  },
                });
              }}
              options={testConfiguration.administration_type_options}
              filledValue={vaccineResult.administration_type}
              isDisabled={patientRefusedService}
            />
          </Form.Group>
        </Col>
        <Col lg={4} sm={6} xs={12}>
          <Form.Group>
            <Form.Label>Site</Form.Label>
            <FloatingLabelSelect
              className={showError && completeReasons.includes("administration_site") ? "is-invalid" : ""}
              name="administration_site"
              value={testConfiguration.administration_site_options.filter(o => o.value === vaccineResult.administration_site)}
              onChange={(as) => {
                updateTest({
                  ...test,
                  saved: false,
                  vaccine_result: {
                    ...vaccineResult,
                    administration_site: as.value,
                  },
                });
              }}
              options={testConfiguration.administration_site_options}
              filledValue={vaccineResult.administration_site}
              isDisabled={patientRefusedService}
            />
          </Form.Group>
        </Col>
      </Row>
      <FabrxCheckbox
        className="my-2"
        onChange={(checked) => {
          updateTest({
            ...test,
            saved: false,
            administered: [null, true].includes(test.administered) ? false : true,
          })
        }}
        checked={patientRefusedService}
        label="Service refused by participant"
      />
    </div>
  );
};

export const VaccineInfoSheetStep = ({
  completeReasons,
  showError,
  test,
  updateTest,
  vaccineInfoSheetProps,
  vaccineResult,
}) => {

  const [
    vaccineInfoSheetVersionApproval,
    vaccineInfoSheetVersion,
    vaccineInfoSheetVersionFileUrl,
    vaccineInfoSheetFlipper,
  ] = [
    vaccineInfoSheetProps?.vaccine_info_sheet_versions[0]?.vaccine_info_sheet_version_approval,
    vaccineInfoSheetProps?.vaccine_info_sheet_versions[0]?.vaccine_info_sheet_version,
    vaccineInfoSheetProps?.vaccine_info_sheet_versions[0]?.vaccine_info_sheet_version_file_url,
    vaccineInfoSheetProps?.vaccine_info_sheet_flipper,
  ];

  const redIconStyle = {
    // makes svg icon color red
    filter: "invert(75%) sepia(93%) saturate(3246%) hue-rotate(313deg) brightness(88%) contrast(123%)",
    width: "20px",
    margin: "0px 0px 3px 0px",
  }

  const visVersionDateInUTC = vaccineInfoSheetVersion?.version_date
    && formatInTimeZone(new Date(vaccineInfoSheetVersion.version_date), 'UTC', 'MM/dd/yyyy');

  return (
    <div className="service-card-section">
      <h4 className="service-card-section-title">Vaccine information sheet</h4>
      {vaccineInfoSheetFlipper && (
        vaccineInfoSheetVersionFileUrl ? (
          <div className="my-4">
            <a href={vaccineInfoSheetVersionFileUrl} target="_blank" className="inter bold">
              Read vaccine fact sheet
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ms-2" />
            </a>
          </div>
        ) : (
          <div className="text-danger my-3">
            No relevant VIS available.
          </div>
        )
      )}
      <FabrxCheckbox
        name="statement_provided"
        onChange={() => {
          updateTest({
            ...test,
            saved: false,
            vaccine_result: {
              ...vaccineResult,
              statement_provided: !vaccineResult.statement_provided,
              vaccine_info_sheet_version_approval_id: !vaccineResult.statement_provided ? vaccineInfoSheetVersionApproval?.id : null,
              vaccine_info_sheet_version_id: !vaccineResult.statement_provided ? vaccineInfoSheetVersion?.id : null,
            },
          });
        }}
        checked={vaccineResult.statement_provided}
        label="Vaccine information sheet or fact sheet provided"
      />
      {(showError && Object.keys(completeReasons).includes("statement_provided")) && (
        <div className="text-danger body-medium-regular">
          <FontAwesomeIcon icon={faExclamationCircle} className="me-2" style={{ fontSize: "0.8rem", marginBottom: "2px" }} />
          Please confirm VIS was provided to participant
        </div>
      )}
    </div>
  );
};

export const ErrorSummary = ({
  completeReasons,
  showError
}) => {
  const completeReasonValues = Object.values(completeReasons || {});
  if (!showError || !completeReasonValues?.length) return <span></span>;
  return (
    <div
      className="my-3 p-3"
      style={{
        backgroundColor: "#FFDDE4",
        borderRadius: "12px",
      }}
    >
      <div className="d-flex">
        <FontAwesomeIcon
          className="mt-1 ms-1 me-3"
          color="#CB2531"
          icon={faExclamationCircle}
          style={{ fontSize: "1.2rem" }}
        />
        <div>
          <div className="body-medium-16-bold mb-2">Missing information</div>
          <span>Please complete </span>
          {completeReasonValues.join(", ")}
        </div>
      </div>
    </div>
  );
};

export const ConfirmationStep = ({
  test,
  updateTest,
  completeReasons,
  showError,
  currentAdministratorName,
  setCurrentAdministratorName,
  currentAdministratorDegree,
  setCurrentAdministratorDegree,
  currentAdministratorInitials,
  setCurrentAdministratorInitials,
}) => {
  const patientRefusedService = test.administered === false;
  const [vaccineResult, testConfiguration] = [
    test.vaccine_result,
    test.test_configuration,
  ];

  useEffect(() => {
    const testData = {
      ...test,
      vaccine_result: {
        ...vaccineResult,
      },
    }
    if (currentAdministratorName || !vaccineResult.administrator_name) {
      testData["vaccine_result"]["administrator_name"] = currentAdministratorName;
    }
    if (currentAdministratorDegree || !vaccineResult.administrator_degree) {
      testData["vaccine_result"]["administrator_degree"] = currentAdministratorDegree;
    }
    if (currentAdministratorInitials || !vaccineResult.administrator_initials) {
      testData["vaccine_result"]["administrator_initials"] = currentAdministratorInitials;
    }
    updateTest(testData)
  }, [currentAdministratorName, currentAdministratorDegree, currentAdministratorInitials])

  return (
    <div className="service-card-section">
      <h4 className="service-card-section-title">Administered by</h4>
      <Row className="my-3">
        <Col sm={6} xs={12}>
          <Form.Group>
            <Form.Label>Name</Form.Label>
            <FloatingLabelInput
              isDisabled={patientRefusedService}
              validation={showError && completeReasons["administrator_name"] ? "is-invalid" : ""}
              name="administrator_name"
              value={vaccineResult.administrator_name}
              onChange={(e) => {
                updateTest({
                  ...test,
                  saved: false,
                  vaccine_result: {
                    ...vaccineResult,
                    administrator_name: e.target.value,
                  },
                });
                setCurrentAdministratorName(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={2} xs={12}>
          <Form.Group>
            <Form.Label>Initials</Form.Label>
            <FloatingLabelInput
              isDisabled={patientRefusedService}
              validation={showError && completeReasons["administrator_initials"] ? "is-invalid" : ""}
              name="administrator_initials"
              value={vaccineResult.administrator_initials}
              onChange={(e) => {
                updateTest({
                  ...test,
                  saved: false,
                  vaccine_result: {
                    ...vaccineResult,
                    administrator_initials: e.target.value,
                  },
                });
                setCurrentAdministratorInitials(e.target.value);
              }}
            />
          </Form.Group>
        </Col>
        <Col sm={4} xs={12}>
          <Form.Group>
            <Form.Label>Position</Form.Label>
            <FloatingLabelSelect
              isDisabled={patientRefusedService}
              className={showError && completeReasons["administrator_degree"] ? "is-invalid" : ""}
              name="administrator_degree"
              value={testConfiguration.administrator_degree_options.filter(o => [o.label, o.value].includes(vaccineResult.administrator_degree))}
              onChange={(e) => {
                updateTest({
                  ...test,
                  saved: false,
                  vaccine_result: {
                    ...vaccineResult,
                    administrator_degree: e.value,
                  },
                });
                setCurrentAdministratorDegree(e.value);
              }}
              options={testConfiguration.administrator_degree_options}
              filledValue={vaccineResult.administrator_degree}
            />
          </Form.Group>
        </Col>
      </Row>
    </div>
  );
};

export const VACCINE_LABELS = {
  label_A4_vaccine: "8.0x11.0 Vaccination Statement",
  label_2_0x1_25_vaccine: "2.0x1.25 Label",
  label_2_0x0_5_vaccine: "2.0x0.5 Label",
  label_2_0x1_0_vaccine: "2.0x1.0 Label",
  label_0_5x1_0_vaccine: "0.5x1.0 Label",
  label_1_25x2_0_vaccine: "1.25x2.0 Label",
}

export const PrintStep = ({ test, updateTest, submitTests, onComplete, stepNumber }) => {

  return (
    <div className="service-card-section">
      <h4 className="service-card-section-title">Print labels</h4>
      {!test.administered ? (
        <div className="text-muted">
          Available after administration has been logged.
        </div>
      ) : Object.keys(VACCINE_LABELS).map(key =>
        <VaccinePdfLabelPrinter
          test={test}
          onPrint={(print) => {
            updateTest({...test, administered: true});
            submitTests([test]).then((response) => {
              print();
              onComplete();
            });
          }}
          label={key}
          name={VACCINE_LABELS[key]}
        />
      )}
    </div>
  );
};

export const NotesStep = ({
  notesRef,
  test,
  updateTest,
}) => {
  return (
    <div className="service-card-section">
      <h4 className="service-card-section-title">Notes</h4>
      <FormControl
        as="textarea"
        ref={notesRef}
        rows={4}
        onChange={(e) => {
          updateTest({
            ...test,
            notes: e.target.value,
          });
        }}
        value={test.notes || ""}
      />
    </div>
  );
};
