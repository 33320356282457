import React, { useContext, useEffect } from 'react';
import '../../../common/locales/i18n';
import { useTranslation } from 'react-i18next';
import { buildCustomizedLabel } from '../../../common/locales/langs';
import FabrxCheckbox from '../../../Registration/primary/FabrxCheckbox';
import ErrorMessage from '../../../common/components/ErrorMessage';
import InsuranceContext from '../../../Insurance/contexts/InsuranceContext';
import PolicyInformationFields from './PolicyInformationFieldsV2';
import NoInsuranceFields from './NoInsuranceFieldsV2';
import * as SurveyOriginal from "survey-react";
import { Model } from "survey-core";
import CheckboxCard from '../../../common/components/CheckBoxCard';
import { Row, Col } from 'react-bootstrap';

const INSURANCE_REQUIRED_FIELDS = ["insurance_company", "insurance_id"];
const MEMBER_NAME_FIELDS = ["insurance_primary_member_first_name", "insurance_primary_member_last_name"];
const MEMBER_DATE_OF_BIRTH_FIELDS = [
  'insurance_primary_member_date_of_birth(2i)',
  'insurance_primary_member_date_of_birth(1i)',
  'insurance_primary_member_date_of_birth(3i)',
];
const SECONDARY_INSURANCE_FIELDS = [
  "insurance_secondary_company",
  "insurance_secondary_id",
  "insurance_secondary_policy_holder"
];
const SECONDARY_MEMBER_FIELDS = ["insurance_secondary_member_first_name", "insurance_secondary_member_last_name"];
const SECONDARY_DATE_OF_BIRTH_FIELDS = [
  'insurance_secondary_member_date_of_birth(2i)',
  'insurance_secondary_member_date_of_birth(1i)',
  'insurance_secondary_member_date_of_birth(3i)',
]
const IAM_POLICYHOLDER = 'i_am';

const INSURANCE_CARD_FIELDS = {
  primary: ["insurance_card_front"],
  secondary: ["insurance_secondary_card_front"]
}

export const validateInsuranceForm = (formAction) => (values) => {
  const errors = {};
  const submitError = (valueKey, errorKey) => {
    if (!values.user[valueKey]) errors[errorKey ? errorKey : valueKey] = 'registration.errors.required';
  }

  const {
    user: {
      insurance_status: insuranceStatus,
      driver_license_number: driverLicense,
      driver_license_state: driverLicenseState,
      social_security_number: socialSecurity,
    },
    test_group: {
      show_only_insurance_survey: showOnlySurveyForWhenNoInsurance,
      insurance_fields_required: requiredFields,
      use_new_surveyjs_validation: newSurveyValidation,
      insurance_survey: insuranceSurvey,
      flipper_flags: flipperFlags,
    },
  } = values;
  if (!(values.user && insuranceStatus !== undefined)) {
    errors.insurance_status = 'registration.errors.option_required';
  }
  if (newSurveyValidation && Object.keys(insuranceSurvey || {}).length > 0) {
    let model;
    if (flipperFlags.upgraded_surveyjs) {
      model = new Model(insuranceSurvey);
    } else {
      model = new SurveyOriginal.Model(insuranceSurvey);
    }
    let data = values.appointment.insurance_answers
    model.data = data || {};
    if (model.hasErrors()) errors.questionnaire = 'registration.errors.survey_unanswered';
  }

  if (requiredFields.includes('insurance_card')) {
    INSURANCE_CARD_FIELDS.primary.map(key => submitError(key))
  }
  if (formAction.showInsuranceInformation) {
    INSURANCE_REQUIRED_FIELDS.concat("insurance_policy_holder").map(key => submitError(key));

    if (formAction.policyHolder !== IAM_POLICYHOLDER) {
      MEMBER_NAME_FIELDS.map(key => submitError(key));
      MEMBER_DATE_OF_BIRTH_FIELDS.map(key => submitError(key, "date_of_birth"));
    }
    if (formAction.showSecondary) {
      SECONDARY_INSURANCE_FIELDS.map(key => submitError(key));
      if (formAction.secondaryPolicyHolder !== IAM_POLICYHOLDER) {
        SECONDARY_MEMBER_FIELDS.map(key => submitError(key));
        SECONDARY_DATE_OF_BIRTH_FIELDS.map(key => submitError(key, "secondary_date_of_birth"));
      }
      if (requiredFields.includes('insurance_card')) {
        INSURANCE_CARD_FIELDS.secondary.map(key => submitError(key))
      }
    }
  } else {
    if (
      !driverLicense &&
      !socialSecurity &&
      formAction.participantHasID &&
      !showOnlySurveyForWhenNoInsurance
    )
      errors.no_insurance = 'registration.errors.field_required';
    if (driverLicense && !driverLicenseState)
      errors.driver_license_state = 'registration.errors.field_required';
  }
  return errors;
};

const InsuranceForm = ({ values, errors, handleChange, setFieldValue }) => {
  const { t, i18n } = useTranslation();
  const { testGroup, formAction, setFormAction } = useContext(InsuranceContext);

  useEffect(() => {
    // To be able to submit insurance form from checkout page
    setFormAction({
      ...formAction,
      insuranceStatus: values.user.insurance_status,
      insuranceId: values.user.insurance_id,
      insuranceCompany: values.user.insurance_company,
      insuranceGroupNumber: values.user.insurance_group_number,
      insurancePrimaryMemberFirstName: values.user.insurance_primary_member_first_name,
      insurancePrimaryMemberLastName: values.user.insurance_primary_member_last_name,
      insuranceCardFront: values.user.insurance_card_front,
      insuranceCardBack: values.user.insurance_card_back,
      insuranceSecondaryCardFront: values.user.insurance_secondary_card_front,
      insuranceSecondaryCardBack: values.user.insurance_secondary_card_back,
      driverLicenseNumber: values.user.driver_license_number,
      socialSecurityNumber: values.user.social_security_number,
      driverLicense: values.user.driver_license,
      insuranceSecondaryId: values.user.insurance_secondary_id,
      insuranceSecondaryCompany: values.user.insurance_secondary_company,
      insuranceSecondaryGroupNumber: values.user.insurance_secondary_group_number,
      insuranceSecondaryPolicyHolder: values.user.insurance_secondary_policy_holder,
      insuranceSecondaryMemberFirstName: values.user.insurance_secondary_member_first_name,
      insuranceSecondaryMemberLastName: values.user.insurance_secondary_member_last_name,
    });
  }, [values]);

  useEffect(() => {
    if (formAction.requireInsurance) setFieldValue('user.insurance_status', 0);
  }, [formAction]);

  const noInsuranceSubmitButton = () => {
    const showNoInsuranceForm = formAction.requestDriversLicense ||
      Object.keys(values.test_group.insurance_survey || {}).length > 0 ||
      formAction.requestSocialSecurityNumber;

    return (
      <CheckboxCard
        name="user[insurance_status]"
        ariaLabel={`${t( buildCustomizedLabel(testGroup.population, 'registration.insurance_status.do_not_have_health_insurance'))} ${values.user.insurance_status === 1 ? " checked" : " unchecked"}`}
        onChange={() => {
          setFieldValue('user.insurance_status', 1);
          setFormAction({
            ...formAction,
            showInsuranceInformation: false,
            showNonInsuranceInformation: showNoInsuranceForm,
            participantHasID: showNoInsuranceForm ? formAction.participantHasID : !formAction.participantHasID,
          });
        }}
        checked={(values.user.insurance_status === 1 || values.user.insurance_status === 'do_not_have_health_insurance')}
        label={t(
          buildCustomizedLabel(
            testGroup.population,
            'registration.insurance_status.do_not_have_health_insurance',
          ),
        )}
        radio
      />
    )
  }

  const showInsuranceInformationFields = () => {
    return(
      <form id="insurance-form">
        <div className="rounded-8 p-2 mb-3">
          <PolicyInformationFields
            setFieldValue={setFieldValue}
            values={values}
            errors={errors}
            handleChange={handleChange}
          />
        </div>
        <div className="rounded-8 p-2 mb-3 mt-2">
          <FabrxCheckbox
            ariaLabel={t('registration.insurance_information.secondary_insurance_label')}
            className='my-2'
            onChange={() => {
              setFormAction({
                ...formAction,
                showSecondary: !!!formAction.showSecondary,
              });
            }}
            checked={formAction.showSecondary}
            label={t('registration.insurance_information.secondary_insurance_label')}
          />
          {formAction.showSecondary && (
            <div>
              <PolicyInformationFields
                setFieldValue={setFieldValue}
                values={values}
                errors={errors}
                handleChange={handleChange}
                secondary={true}
                onShowInsuranceBack={()=>{
                  setFormAction({
                    ...formAction,
                    showSecondaryInsuranceBack: !formAction.showSecondaryInsuranceBack
                  }
                )}}
                names={{
                  insuranceCardFront: "insurance_secondary_card_front",
                  showInsuranceBack: "showSecondaryInsuranceBack",
                  insuranceCardBack: "insurance_secondary_card_back",
                  insuranceCompany: "insurance_secondary_company",
                  insuranceId: "insurance_secondary_id",
                  insuranceGroupNumber: "insurance_secondary_group_number",
                  insurancePolicyHolder: "insurance_secondary_policy_holder",
                }}
                policyHolderNames={{
                  policyHolderKey: "secondaryPolicyHolder",
                  primaryMemberFirstName: "insurance_secondary_member_first_name",
                  primaryMemberLastName: "insurance_secondary_member_last_name",
                  secondary: true,
                  dob: "secondary_date_of_birth",
                }}
              />
            </div>
          )}
        </div>
      </form>
    )
  }

  return (
    <div>
      <React.Fragment>
        {errors.insurance_status && <ErrorMessage id="insuranceStatusDesc" message={t(errors.insurance_status)} />}
        <input
          type="hidden"
          value={values.user.insurance_status === 0}
          name="user[insurance_status][has_insurance]"
          autoComplete="off"
        />
        <input
          type="hidden"
          value={values.user.insurance_status === 1}
          name="user[insurance_status][no_insurance]"
          autoComplete="off"
        />
        <Row>
          <Col xs={12} md={6} className="pe-3">
            <CheckboxCard
              name="user[insurance_status]"
              ariaLabel={ `${t( buildCustomizedLabel(testGroup.population, 'registration.insurance_status.have_health_insurance'))}`}
              radio
              label={t(
                buildCustomizedLabel(
                  testGroup.population,
                  'registration.insurance_status.have_health_insurance',
                ),
              )}
              checked={values.user.insurance_status === 0 || values.user.insurance_status === 'have_health_insurance' || formAction.showInsuranceInformation}
              onChange={() => {
                setFieldValue('user.insurance_status', 0);
                setFormAction({
                  ...formAction,
                  showInsuranceInformation: true,
                  showNonInsuranceInformation: false,
                });
              }}
            />
          </Col>
          {!formAction.requireInsurance && (
            <Col xs={12} md={6} className="ps-3">
              {noInsuranceSubmitButton()}
            </Col>
          )}
        </Row>
        {formAction.showInsuranceInformation && showInsuranceInformationFields()}
      </React.Fragment>

      {formAction.showNonInsuranceInformation &&
      (formAction.requestDriversLicense || formAction.requestSocialSecurityNumber) &&
        <NoInsuranceFields
          values={values}
          errors={errors}
          handleChange={handleChange}
          setFieldValue={setFieldValue}
        />
      }
    </div>
  );
};

export default InsuranceForm;
