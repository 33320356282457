import React, { FC, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/pro-regular-svg-icons';
import startCase from 'lodash/startCase';

import SelectableTable from '../../common/components/Table/components/SelectableTable';
import { AssignableUser, Test, Appointment, fetchAppointmentTests } from './Index';
import AssigneeDropdown from './AssigneeDropdown';
import TooltipWrapper from '../../common/components/TooltipWrapper';
import Wrapped from '../../common/components/Wrapped';
import MultiAssignmentModal from './MultiAssignmentModal';

const ParticipantCell: FC<Appointment> = ({ user }) => (
  <div className="d-flex flex-column">
    <span className="font-weight-bold">{user.fullName}</span>
    <span>
      {user.dateOfBirth} ({user.age})
    </span>
  </div>
);

const StatusCellSection = ({ caseReportStatus }) => {
  return (
    <div
      className="d-flex"
      {...(caseReportStatus.isResolved ? { 'data-resolved': true } : {})}
    >
      <div
        className="status-badge text-nowrap"
        data-status={caseReportStatus.value}
      >
        {caseReportStatus.label}
      </div>
    </div>
  );
};

const StatusCell: FC<Appointment> = ({ caseReportStatuses }) => {
  return (
    <div className="d-flex flex-column">
      {caseReportStatuses.length > 0 ? (
        <React.Fragment>
          <StatusCellSection caseReportStatus={caseReportStatuses[0]} />
          {caseReportStatuses.length > 1 && (
            <StatusCellSection caseReportStatus={caseReportStatuses[1]} />
          )}
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="d-flex">
            <div className="status-badge text-nowrap" data-status={'new'}>
              {'New'}
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

const AppointmentDateCell: FC<Appointment> = ({
  localizedStartsAtDate,
  localizedStartsAtTime,
}) => (
  <div className="d-flex flex-column">
    <span className="font-weight-bold">{localizedStartsAtDate}</span>
    <span>{localizedStartsAtTime}</span>
  </div>
);

const ResultCell: FC<Appointment> = ({
  id,
  abnormalResultsCount,
  positiveResultsCount,
  pendingResultsCount,
}) => {
  const [tooltipContent, setTooltipContent] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      const tests = await fetchAppointmentTests(id);
      const content = tests
        .map((test) =>
          test.results
            .map((r) =>
              test.results.length > 1
                ? `${r.label}: ${r.labelledValue || r.value || ''}`
                : r.labelledValue || r.value || '',
            )
            .join('\n'),
        )
        .join('\n');
      setTooltipContent(content);
    };

    fetchData();
  }, [id]);

  return (
    <div className="d-flex flex-column">
      <Wrapped
        WrapperComponent={TooltipWrapper}
        componentProps={{
          tooltipContent,
        }}
      >
        <span style={{ cursor: 'help' }}>
          <div style={{ fontSize: '10px' }}>
            <div className="d-flex flex-column">
              {(() => {
                if (pendingResultsCount) {
                  return (
                    <div>
                      {pendingResultsCount} pending result
                      {pendingResultsCount > 1 ? 's' : ''}
                    </div>
                  );
                } else if (abnormalResultsCount || positiveResultsCount) {
                  return (
                    <div>
                      {abnormalResultsCount + positiveResultsCount} abnormal
                      result
                      {abnormalResultsCount + positiveResultsCount > 1
                        ? 's'
                        : ''}
                    </div>
                  );
                } else if (pendingResultsCount) {
                  return (
                    <div>
                      {pendingResultsCount} 'invalid'
                      {pendingResultsCount > 1 ? 's' : ''}
                    </div>
                  );
                } else {
                  return <div>0 results</div>;
                }
              })()}
            </div>
          </div>
        </span>
      </Wrapped>
    </div>
  );
};

const TestConfigurationCell: FC<Appointment> = ({ id, testConfigurations }) => {
  const [tooltipContent, setTooltipContent] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      const tests = await fetchAppointmentTests(id);
      const content = tests
        .map(
          (test) =>
            `${test.testStrip?.barcode || test.uid} - ${
              test.testConfiguration.displayName
            }`,
        )
        .join('\n');
      setTooltipContent(content);
    };

    fetchData();
  }, [id]);

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column">
        <TooltipWrapper tooltipContent={tooltipContent} placement="right">
          <span
            className="test-name font-weight-bold"
            style={{ cursor: 'help' }}
          >
            {testConfigurations &&
              testConfigurations
                .map((testConfiguration) => testConfiguration.displayName)
                .join('\n')}
          </span>
        </TooltipWrapper>
      </div>
    </div>
  );
};

const LastAttemptCell: FC<Appointment> = ({ lastAttempts }) => (
  <div className="d-flex flex-column">
    {lastAttempts &&
      lastAttempts.map((lastAttempt, idx) => (
        <React.Fragment key={idx}>
          <span className="font-weight-bold">{lastAttempt?.date}</span>
          <span>{lastAttempt?.time}</span>
          <div className="d-flex flex-column">
            <span className="font-weight-bold">
              {lastAttempt?.adminFullName}
            </span>
          </div>
        </React.Fragment>
      ))}
  </div>
);

const TimeZoneCell: FC<Appointment> = ({ timeZone }) => (
  <span>{timeZone.replace(' Time (US & Canada)', '')}</span>
);

const GoToCaseCell: FC<Appointment> = ({ caseReportPath }) => (
  <a href={caseReportPath} target="_blank">
    <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
  </a>
);

interface IndexTableProps {
  appointments: Appointment[];
  assignableUsers: AssignableUser[];
  onSelectedChange: (selectedAppointments: number[]) => void;
}

const IndexTable: FC<IndexTableProps> = ({
  appointments,
  assignableUsers,
  onSelectedChange,
}) => (
  <SelectableTable
    data={appointments}
    headers={[
      { name: 'Participant', CellComponent: ParticipantCell },
      { name: 'Appt time', CellComponent: AppointmentDateCell },
      { name: 'Results', CellComponent: ResultCell },
      { name: 'Test(s)', CellComponent: TestConfigurationCell },
      { name: 'Status', CellComponent: StatusCell },
      { name: 'Last attempt', CellComponent: LastAttemptCell },
      {
        name: 'Assigned to',
        CellComponent: (appointment) => (
          <MultiAssignmentModal
            appointment={appointment}
            assignableUsers={assignableUsers}
          />
        ),
      },
      { name: '', CellComponent: GoToCaseCell },
    ]}
    onSelectedChange={onSelectedChange}
  />
);

export default IndexTable;
