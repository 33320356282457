import React, { FC, useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import {
  Appointment,
  Test,
  AssignableUser,
  fetchAppointmentTests,
} from './Index';
import Select from '../../common/components/Select';
import AssigneeDropdown from './AssigneeDropdown';
import ButtonControl from '../../common/components/Select/components/ButtonControl';
import TooltipWrapper from '../../common/components/TooltipWrapper';

interface MultiAssignmentModalProps {
  appointment: Appointment;
  assignableUsers: AssignableUser[];
}

const toastr = window['toastr'];

const MultiAssignmentModal: FC<MultiAssignmentModalProps> = ({
  appointment,
  assignableUsers,
}) => {
  const tests = []; //fetchAppointmentTests(appointment.id);

  const assignedUsers = tests.map((test) => test.assignedUser);
  const uniqueAssignedUsers = [
    ...new Set(assignedUsers.map((user) => user.id)),
  ];
  const [selectedUsers, setSelectedUsers] = useState(uniqueAssignedUsers);
  const [selectedUser, setSelectedUser] = useState();
  const [flush, setFlush] = useState(false);
  const [show, setShow] = useState(false);

  const onChange = (userId: number) => {
    if (!selectedUsers.includes(userId)) {
      setSelectedUsers([...selectedUsers, userId]);
    }
  };

  const handleRemoveUser = (userId: number, appointment: Appointment) => {
    if (tests.some((test) => test.assignedUser.id === userId)) {
      toastr.error('Cannot remove user with assigned tests');
    } else if (window.confirm('Are you sure you want to remove this user?')) {
      setSelectedUsers(selectedUsers.filter((id) => id !== userId));
    }
  };

  const handleClose = () => setShow(false);

  const AssignableTests = ({ appointment, assignableUser }) => (
    <div className="d-flex flex-column">
      {appointment.tests.map((test: Test, idx: number) => (
        <div className="d-flex flex-column px-4 py-2" key={idx}>
          <TooltipWrapper
            tooltipContent={`${test.testStrip?.barcode || test.uid} - ${
              test.testConfiguration?.displayName
            }`}
            placement="right"
          >
            <div
              className={`btn ${
                test.assignedUser
                  ? test.assignedUser.id === assignableUser.id
                    ? 'btn-outline-dark bg-dark-gray'
                    : 'btn-outline-gray bg-white disabled'
                  : 'btn-outline-primary'
              }`}
              onClick={() => {
                if (!test.assignedUser) {
                  // assignTest(appointment, assignableUser.id);
                }
              }}
            >
              {test.testConfiguration?.displayName}
            </div>
          </TooltipWrapper>
        </div>
      ))}
    </div>
  );

  return (
    <div className="d-flex flex-column">
      {true ? (
        <React.Fragment>
          <a onClick={() => setShow(true)}>Multiple</a>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton={true}>
              <Modal.Title>Multiple Assignment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Select
                value={selectedUser}
                options={assignableUsers}
                valueKey="id"
                labelKey="fullName"
                onChange={onChange}
                blankLabel="Unassigned"
                placeholder={'Select clinicians'}
                borderless={flush}
                components={{ Control: ButtonControl }}
              />
              <label>Clinicians:</label>
              {selectedUsers.map((userId) => (
                <div key={userId} className="d-flex align-items-center">
                  <span>
                    {
                      assignableUsers.find((user) => user.id === userId)
                        ?.fullName
                    }
                  </span>
                  <Button
                    variant="link"
                    onClick={() => handleRemoveUser(userId, appointment)}
                    className="ms-2"
                  >
                    x
                  </Button>
                  <AssignableTests
                    assignableUser={assignableUsers.find(
                      (user) => user.id === userId,
                    )}
                    appointment={appointment}
                  />
                </div>
              ))}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
      ) : (
        <AssigneeDropdown
          flush
          selectedAppointments={[appointment]}
          assignableUsers={assignableUsers}
          {...appointment}
        />
      )}
    </div>
  );
};

export default MultiAssignmentModal;
