import React, { FC, Reducer, useEffect, useReducer, useState } from 'react';

import axios from 'axios';

import IndexTable from './IndexTable';
import TopBar from './TopBar';
import Filters from './Filters';
import { DropdownOption } from '../../common/components/SearchFilters/FilterDropdown';
import TablePagination from '../../common/components/TablePagination';
import { pushQueryParam } from '../../common/utils/history';
import LoadingOverlay from '../../common/components/LoadingOverlay';

interface User {
  fullName: string;
  dateOfBirth: string;
  lastPositiveAt: string;
  phoneFormatted: string;
  phoneNumber: string;
  state: string;
  age: number;
}

interface AssignedUser {
  id: number;
  fullName: string;
}

interface LastAttempt {
  adminId: number;
  adminFullName: string;
  date: string;
  time: string;
}

interface TestConfiguration {
  id: number;
  displayName: string;
  serviceSpecification: string;
}

interface TestStrip {
  barcode: string;
}

interface TestGroup {
  id: number;
  name: string;
  slug: string;
}

interface TestResult {
  label: string;
  value: string;
  labelledValue?: string;
  testConfigurationId: number;
  localizedResultsAtDate: string;
  localizedResultsAtTime: string;
}

interface TestCaseManagerResultSchema {
  caseManagerResults: TestCaseManagerResultSchemaItem[];
}

interface TestCaseManagerResultSchemaItem {
  label: string;
  value_type: string;
}

export interface CaseReportStatus {
  value: string;
  label: string;
  isResolved: boolean;
}

export interface Appointment {
  id: number;
  uids: string[];
  localizedStartsAtDate: string;
  localizedStartsAtTime: string;
  caseReportStatuses: CaseReportStatus[];
  user: User;
  assignedUsers?: AssignedUser[];
  attemptsCounts: number[];
  caseReportPath: string;
  lastAttempts?: LastAttempt[];
  timeZone: string;
  testConfigurations?: TestConfiguration[];
  testGroup: TestGroup;
  abnormalResultsCount: number;
  positiveResultsCount: number;
  pendingResultsCount: number;
  resultsAtList?: string[];
  resultSchema?: TestCaseManagerResultSchema;
}

export interface AssignableUser {
  id: number;
  fullName: string;
}

export interface FilterOptions {
  assignees: DropdownOption[];
  organizations: DropdownOption[];
  statuses: DropdownOption[];
  attempts: DropdownOption[];
  testConfigurations: DropdownOption[];
  caseManagerResults: DropdownOption[];
  requisitioningPhysicians: DropdownOption[];
  timeZones: DropdownOption[];
}

interface Meta {
  currentPage: number;
  nextPage?: number;
  prevPage?: number;
  totalPages: number;
  totalCount: number;
  perPage: number;
}

interface State {
  appointmentsCount?: number;
  appointments: Appointment[];
  selectedAppointments: Appointment[];
  meta?: Meta;
  currentPage: number;
  loading: boolean;
  errored: boolean;
}

interface SetAppointmentsCountAction {
  type: 'SET_APPOINTMENTS_COUNT';
  count: number;
}

interface SetDataAction {
  type: 'SET_DATA';
  payload: {
    appointments: Appointment[];
    meta?: Meta;
  };
}

interface SetSelectedAppointmentsAction {
  type: 'SET_SELECTED_APPOINTMENTS';
  testIds: number[];
}

interface SetPageAction {
  type: 'SET_PAGE';
  page: number;
}

interface StartLoadAction {
  type: 'SET_LOADING';
}

interface ErrorAction {
  type: 'ERROR';
}

type Action =
  | SetAppointmentsCountAction
  | SetDataAction
  | SetSelectedAppointmentsAction
  | SetPageAction
  | StartLoadAction
  | ErrorAction;

const reducer = (state: State, action: any): State => {
  switch (action.type) {
    case 'SET_APPOINTMENTS_COUNT':
      return {
        ...state,
        appointmentsCount: action.count,
      };
    case 'SET_DATA':
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    case 'SET_SELECTED_APPOINTMENTS':
      return {
        ...state,
        selectedAppointments: state.appointments.filter((appointment) =>
          action.appointmentIds.includes(appointment.id),
        ),
      };
    case 'SET_PAGE':
      pushQueryParam('page', action.page, { turbolinks: {} });

      return {
        ...state,
        currentPage: action.page,
      };
    case 'SET_LOADING':
      return {
        ...state,
        loading: true,
      };
    case 'ERROR':
      return {
        ...state,
        loading: false,
        errored: true,
      };
    default:
      return state;
  }
};

const currentPathToJsonFormat = ({ countOnly = false } = {}) => {
  const url = new URL(window.location.href);
  if (countOnly) {
    url.searchParams.append('count_only', 'true');
  }

  return `${url.pathname}.json${url.search}`;
};

const fetchAppointmentsCount = async () =>
  axios
    .get(currentPathToJsonFormat({ countOnly: true }))
    .then((response) => response.data);

const fetchAppointments = async () =>
  axios.get(currentPathToJsonFormat()).then(({ data }) => {
    return data;
  });

export const fetchAppointmentTests = async (id: number) => {
  // Confirm this is the correct endpoint
  const response = await fetch(`/appointments/${id}/tests`);
  return response.json();
};

interface IndexProps {
  assignableUsers: AssignableUser[];
  filterOptions: FilterOptions;
  skipPagination?: boolean;
}

const Index: FC<IndexProps> = ({
  assignableUsers,
  filterOptions,
  skipPagination = false,
}) => {
  const initialState: State = {
    appointments: [],
    selectedAppointments: [],
    currentPage: 0,
    loading: false,
    errored: false,
  };

  const [state, dispatch] = useReducer<Reducer<State, Action>>(
    reducer,
    initialState,
  );

  // Prevent initial load of tests if no query params are present
  const skipInitialLoad = !new URLSearchParams(window.location.search).size;

  const setSelectedTests = (testIds: number[]) =>
    dispatch({ type: 'SET_SELECTED_APPOINTMENTS', testIds });

  useEffect(() => {
    if (skipInitialLoad) return;

    dispatch({ type: 'SET_LOADING' });

    fetchAppointments()
      .then((payload) => {
        dispatch({ type: 'SET_DATA', payload });
      })
      .catch((_) => {
        dispatch({ type: 'ERROR' });
      });
  }, [state.currentPage]);

  // Delayed load of total appointments count
  useEffect(() => {
    if (skipInitialLoad) return;

    const timeout = setTimeout(() => {
      fetchAppointmentsCount().then(({ appointmentsCount: count }) => {
        dispatch({ type: 'SET_APPOINTMENTS_COUNT', count });
      });
    }, 750);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <React.Fragment>
      <div className="case-manager">
        <div style={{ marginBottom: 32 }}>
          <Filters {...filterOptions} />
        </div>
        <div style={{ marginBottom: 32 }}>
          <TopBar
            showErrorMessage={state.errored}
            appointmentsCount={skipInitialLoad ? 0 : state.appointmentsCount}
            selectedAppointments={state.selectedAppointments}
            assignableUsers={assignableUsers}
          />
        </div>
        <LoadingOverlay loading={state.loading}>
          {!state.errored && (
            <IndexTable
              appointments={state.appointments}
              assignableUsers={assignableUsers}
              onSelectedChange={setSelectedTests}
            />
          )}
        </LoadingOverlay>
        {skipInitialLoad && (
          <div className="my-4 text-center text-muted fw-bold">
            Please apply filters to see results.
          </div>
        )}
      </div>

      {!skipPagination && state.appointments.length > 0 && (
        <TablePagination
          {...state.meta}
          setPage={(page: number) => dispatch({ type: 'SET_PAGE', page })}
        />
      )}
    </React.Fragment>
  );
};

export default Index;
